import { request } from './request';
import { getCookie } from 'utils/cookie';

class ClassAPI {
  setToken = () => {
    return getCookie('aupd_token');
  }

  middleware = () => next => action => {
    const returnValue = next(action);
    const token = getCookie('aupd_token');

    this.setToken(token);

    return returnValue;
  };

  searchRepositories = (params) => request({
    url: '/search/repositories',
    method: 'GET',
    params
  });

  getUsers = () => request({
    url: '/user',
    method: 'GET',
  });

  getRoles = () => request({
    url: '/roles',
    method: 'GET',
  });

  authRegion = ({ data }) => request({
    url: '/v1/regional/authorize',
    method: 'POST',
    data
  })

  getUserInfo = () => {
    const contur = getCookie('auth_flag') || 'main'
    return request({
      url: `/v1/sudir/${contur}/user/info`,
      method: 'GET',
      token: getCookie('aupd_token')
    })
  }

  getDevices = () => request({
    url: '/v1/device/user',
    method: 'GET',
    token: getCookie('aupd_token')
  })

  deleteDevice = ({ id }) => request({
    url: `/v1/device/user/${id}`,
    method: 'DELETE',
    token: getCookie('aupd_token')
  })


  addDevice = ({ roleId, subsystem, deviceAlias }) => request({
    url: `/v2/save_choice`,
    method: 'POST',
    token: getCookie('aupd_token'),
    data: {
      subsystem,
      roleId,
      deviceAlias,
    }
  })

  editDevice = (data) => request({
    url: `/v1/device/user`,
    method: 'PUT',
    data,
    token: getCookie('aupd_token')
  })

  deactivateNotification = () => request({
    url: `/v1/device/notification/true`,
    method: 'GET',
    token: getCookie('aupd_token')
  })

  getRegionUser = (params) => request({
    url: '/v1/user/loginPassword',
    method: 'GET',
    params
  });

  tokenGenerate = ({ userId, roleId, subsystem }) => request({
    url: '/v1/token/generate',
    method: 'GET',
    params: { userId, roleId, subsystem }
  })

  getChildren = ({ sso_id }) =>
    request({
      url: `/v1/user/childrens`,
      method: 'GET',
      params: { sso_id, timeout: 20 }
    })

  getContacts = () =>
    request({
      url: `/v1/sudir/user/info`,
      method: 'GET'
    })

  registerChildren = (data, mesh_id) => {
    // const contur = getCookie('auth_flag') || 'main'
    return request({
      // url: `/v1/sudir/${contur}/child/register`,
      url:`/v3/auth/create-child`,
      method: 'PUT',
      data:{
        ...data,
        meshId: mesh_id
      },
      // params: {
      //   mesh_id
      // }
    })
  }

  changePassword = (data, ssoId) => {
    // const contur = getCookie('auth_flag') || 'main'
    return request({
      url: `/v3/auth/set-child-password`,
      // url: `/v1/sudir/${contur}/${ssoId}/password`,
      method: 'POST',
      data: {
        ssoId,
        password: data?.value
      }
    })
  }

  getLogin = (data) =>
    request({
      url: `/v3/auth/generate-login`,
      method: 'POST',
      data,
    })

  sendActivateAccount = (data) =>
    request({
      url: '/v1/user/adactivate',
      method: 'POST',
      data
    })

  sendActivateAccountPwd = (data) =>
    request({
      url: '/v1/user/adactivate/pwd',
      method: 'POST',
      data
    })

  /**
   * Получение PIN
   * @returns {Promise<*>}
   */
  getPin = () =>
    request({
      url: '/v3/pin',
      method: 'GET'
    })

  /**
   * Генерация PIN
   * @returns {Promise<*>}
   */
  updatePin = () =>
    request({
      url: '/v3/pin',
      method: 'POST'
    })

  requestRight = ({ ssoId }) => {
    const contur = getCookie('auth_flag') || 'main'
    return request({
      url: `/v1/sudir/${contur}/${ssoId}/rights`,
      method: 'GET'
    })
  }

  saveSortedChoice = (data) => {
    return request({
      url: `/v2/sorted`,
      method: 'PUT',
      data
    })
  }

  getExternalToken = () => {
    return request({
      url: `/v2/external-partners/generate-token`,
      method: 'POST',
    })
  }

  getExternalPartners = () => {
    return request({
      url: `/v2/external-partners/list`,
      method: 'GET',
    })
  }

  getEsiaLinking = () => {
    return request({
      url: `/v3/auth/esia/linking`,
      method: 'GET',
    }, true)
  }

  deleteEsiaLinking = ({ data }) => request({
    url: '/v3/auth/esia/linking',
    method: 'DELETE',
    data
  }, true)

  linkExternalPartners = (params, UUID) => {
    return request({
      url: `/v2/external-partners/account-linking/${UUID}`,
      method: 'POST',
      params
    })
  }

  deleteExternalPartners = (id) => {
    return request({
      url: `/v2/external-partners/${id}`,
      method: 'DELETE',
    }, true)
  }

}
const API = new ClassAPI();

export default API;
