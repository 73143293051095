import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'
import ContextWorker, { THEME_TYPE } from '../../contextWorker'
import { GlobalContext } from '../../context'
import { get } from 'lodash'
import { metricsID } from "../../utils/metrics";

const options = {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:false
}
const YaMetrika = () => {
  ContextWorker()
  const { context } = useContext(GlobalContext)
  const globalTheme = get(context, 'globalTheme')
  const location = useLocation()
  if(location.pathname === '/esia') {
    return (
      <YMInitializer accounts={[metricsID.REGION]} options={options} version="2" />
    )
  }
  if(location.pathname === '/') {
    return (
      <YMInitializer accounts={[metricsID.MSK]} options={options} version="2" />
    )
  }
  const account = globalTheme === THEME_TYPE.ESIA ? metricsID.REGION : metricsID.MSK
  return (
    <YMInitializer accounts={[account]} options={options} version="2" />
  )
}

export default YaMetrika
