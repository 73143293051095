import ym from 'react-yandex-metrika';
import {regionConfigValue} from "./regionConfigValue";

export const metricsID = {
  MSK: 71568595,
  REGION: 90856974,
}

export const metricsHost = {
  MSK: 'https://school.mos.ru',
  REGION: '',
}

export const metricsGoals = {
  WINDOW_SELECT_ROLE: 'windowSelectRole',
  SERVICES_SWITCH: 'servicesSwitch',
  ROLES_SWITCH: 'rolesSwitch',
}

export const YMInit = (user, rgn) => {
  const config = regionConfigValue();

  if (config?.SERVICE_URL === metricsHost.MSK) {
    ym('userParams', {
      sub: user.userId,
      role: user?.roles?.map(role => role.title).join(', ') || '',
      sex: user?.info?.gender || '',
      region: rgn,
      UserID: user.userId
    })
  }
}

export const reachGoal = (name, sub, nameServices, roles) => {
  const config = regionConfigValue();
  if (config?.SERVICE_URL === metricsHost.MSK) {
    ym('reachGoal', name, { sub, nameServices, role: roles?.map(role => role.title).join(', ') || '' })
  }
}
